<template>
  <Toast />
  <div class="gf-container">
    <Card class="p-py-6">
      <template #title> References </template>
      <template #subtitle> </template>
      <template #content>
          <div class="refContainer">
            <Accordion :multiple="true" :activeIndex="[0]">
              <AccordionTab>
                <template #header>
                  <div>
                    <strong class="p-pr-2">GeneFriends 2022: </strong
                    ><br class="br-xs" />
                    gene co-expression databases and tools for humans and model organisms
                  </div>
                </template>
                <pre>
<!-- <code>
Raina P, Guinea R, Chatsirisupachai K, Lopes I, Farooq Z, Guinea C, Solyom C,  de Magalhães JP. GeneFriends 2021: Updated co-expression databases and tools for human and mouse genes and transcripts. Biorxiv, 2021 
<a target="_blank" rel="noopener noreferrer" href="https://www.biorxiv.org/content/10.1101/2021.01.10.426125v1">[bioRxiv]</a>
</code> -->
<code>
Priyanka Raina, Rodrigo Guinea, Kasit Chatsirisupachai, Inês Lopes, Zoya Farooq, Cristina Guinea, Csaba-Attila Solyom, João Pedro de Magalhães, GeneFriends: gene co-expression databases and tools for humans and model organisms, Nucleic Acids Research, 2022;, gkac1031, https://doi.org/10.1093/nar/gkac1031
<a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.1093/nar/gkac1031">[Nucleic Acids Research]</a>
</code>
                            </pre>
              </AccordionTab>
            </Accordion>

            <Button
              icon="pi pi-copy"
              class="p-button-text p-button-lg"
              @click.prevent="doCopyref2"
              style="color: #ffffff"
            />
            <Accordion>
              <AccordionTab>
                <template #header>
                  <div>
                    <strong class="p-pr-2">GeneFriends 2015: </strong
                    ><br class="br-xs" />
                    a human RNA-seq-based gene and transcript co-expression
                    database
                  </div>
                </template>
                <pre>
<code>
Sipko van Dam, Thomas Craig, João Pedro de Magalhães, GeneFriends: a human RNA-seq-based gene and transcript co-expression database, Nucleic Acids Research, Volume 43, Issue D1, 28 January 2015, Pages D1124–D1132, https://doi.org/10.1093/nar/gku1042
<a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.1093/nar/gku1042">[Nucleic Acids Research]</a>
</code>
                                        </pre>
              </AccordionTab>
            </Accordion>

            <Button
              icon="pi pi-copy"
              class="p-button-text p-button-lg"
              @click.prevent="doCopyref1"
              style="color: #ffffff"
            />
          </div>
      </template>
    </Card>
  </div>
</template>
<script>
import { copyText } from "vue3-clipboard";
import { useToast } from "primevue/usetoast";
import { ref } from "vue";
export default {
  name: "References",
  setup() {
    const toast = useToast();
/*     const ref1 = ref(`@article{Raina2021,
                          doi = {10.1101/2021.01.10.426125},
                          url = {https://doi.org/10.1101/2021.01.10.426125},
                          year = {2021},
                          month = jan,
                          publisher = {Cold Spring Harbor Laboratory},
                          author = {Priyanka Raina and Rodrigo Guinea and Kasit Chatsirisupachai and In{\^{e}}s Lopes and Zoya Farooq and Cristina Guinea and Csaba-Attila Solyom and Jo{\~{a}}o Pedro de Magalh{\~{a}}es},
                          title = {{GeneFriends} 2021: Updated co-expression databases and tools for human and mouse genes and transcripts}
                        }`); */

const ref1 = ref(`@article{10.1093/nar/gkac1031,
                          author = {Raina, Priyanka and Guinea, Rodrigo and Chatsirisupachai, Kasit and Lopes, Inês and Farooq, Zoya and Guinea, Cristina and Solyom, Csaba-Attila and de Magalhães, João Pedro},
                          title = "{GeneFriends: gene co-expression databases and tools for humans and model organisms}",
                          journal = {Nucleic Acids Research},
                          year = {2022},
                          month = {12},
                          abstract = "{Gene co-expression analysis has emerged as a powerful method to provide insights into gene function and regulation. The rapid growth of publicly available RNA-sequencing (RNA-seq) data has created opportunities for researchers to employ this abundant data to help decipher the complexity and biology of genomes. Co-expression networks have proven effective for inferring the relationship between the genes, for gene prioritization and for assigning function to poorly annotated genes based on their co-expressed partners. To facilitate such analyses we created previously an online co-expression tool for humans and mice entitled GeneFriends. To continue providing a valuable tool to the scientific community, we have now updated the GeneFriends database and website. Here, we present the new version of GeneFriends, which includes gene and transcript co-expression networks based on RNA-seq data from 46 475 human and 34 322 mouse samples. The new database also encompasses tissue-specific gene co-expression networks for 20 human and 21 mouse tissues, dataset-specific gene co-expression maps based on TCGA and GTEx projects and gene co-expression networks for additional seven model organisms (fruit fly, zebrafish, worm, rat, yeast, cow and chicken). GeneFriends is freely available at http://www.genefriends.org/.}",
                          issn = {0305-1048},
                          doi = {10.1093/nar/gkac1031},
                          url = {https://doi.org/10.1093/nar/gkac1031},
                          note = {gkac1031},
                          eprint = {https://academic.oup.com/nar/advance-article-pdf/doi/10.1093/nar/gkac1031/47488272/gkac1031.pdf},
                        }`);

    const ref2 = ref(`@article{10.1093/nar/gku1042,
    author = {van Dam, Sipko and Craig, Thomas and de Magalhães, João Pedro},
    title = "{GeneFriends: a human RNA-seq-based gene and transcript co-expression database}",
    journal = {Nucleic Acids Research},
    volume = {43},
    number = {D1},
    pages = {D1124-D1132},
    year = {2014},
    month = {10},
    abstract = "{Co-expression networks have proven effective at assigning putative functions to genes based on the functional annotation of their co-expressed partners, in candidate gene prioritization studies and in improving our understanding of regulatory networks. The growing number of genome resequencing efforts and genome-wide association studies often identify loci containing novel genes and there is a need to infer their functions and interaction partners. To facilitate this we have expanded GeneFriends, an online database that allows users to identify co-expressed genes with one or more user-defined genes. This expansion entails an RNA-seq-based co-expression map that includes genes and transcripts that are not present in the microarray-based co-expression maps, including over 10 000 non-coding RNAs. The results users obtain from GeneFriends include a co-expression network as well as a summary of the functional enrichment among the co-expressed genes. Novel insights can be gathered from this database for different splice variants and ncRNAs, such as microRNAs and lincRNAs. Furthermore, our updated tool allows candidate transcripts to be linked to diseases and processes using a guilt-by-association approach. GeneFriends is freely available from http://www.GeneFriends.org and can be used to quickly identify and rank candidate targets relevant to the process or disease under study.}",
    issn = {0305-1048},
    doi = {10.1093/nar/gku1042},
    url = {https://doi.org/10.1093/nar/gku1042},
    eprint = {https://academic.oup.com/nar/article-pdf/43/D1/D1124/17436007/gku1042.pdf},
}
`);

    const doCopyref1 = () => {
      copyText(ref1.value, undefined, (error, event) => {
        if (error) {
          alert("Can not copy");
          console.log(error);
        } else {
          toast.add({
            severity: "success",
            summary: "Copied!",
            detail: "BibteX copied to clipboard",
            life: 3000,
          });
        }
      });
    };

    const doCopyref2 = () => {
      copyText(ref2.value, undefined, (error, event) => {
        if (error) {
          alert("Can not copy");
          console.log(error);
        } else {
          toast.add({
            severity: "success",
            summary: "Copied!",
            detail: "BibteX copied to clipboard",
            life: 3000,
          });
        }
      });
    };

    return {
      doCopyref1,
      doCopyref2,
    };
  },
};
</script>

<style scoped>
.refContainer {
  display:grid;
  grid-template-columns: 90% 1fr;
  gap: 10px;
  justify-items: top;
  align-items: top;
}
.p-accordion p {
  line-height: 1.5;
  margin: 0;
}
.p-button{
  height: 52px;
  width: 52px;
  background: lightblue !important;
}
pre {
  overflow: auto;
  background-color: #ffffff;
}
</style>